import { graphql } from "gatsby";
import "katex/dist/katex.min.css";
import React from "react";
import Layout from "../components/Layout";
import PostStyles from "./Post.module.scss";
import CONSTANTS from "../constants";
import { Link } from "gatsby";
import { Helmet } from "react-helmet";
// import { Link } from "gatsby";

function Template({ data, location }) {
  const { markdownRemark } = data; // data.markdownRemark holds our post data
  const {
    html,
    fields: { slug },
  } = markdownRemark;
  const {
    frontmatter: { author, category,description, title, date },
  } = markdownRemark;
  const blogImagePath = slug.replace(CONSTANTS.BLOG_PATH, "");
  var pageUrl = (location.href || "").replace(/^http(s?):/i, "");
  var facebookUrl = "https://www.facebook.com/sharer/sharer.php?u=https%3A" + pageUrl;
  var linkedinUrl = "http://www.linkedin.com/shareArticle?mini=true&url=https%3A" + pageUrl + '&title=' + title + "&summary=&source=";
  return (
    <Layout onPostPage={true}>
      <Helmet>
        <title>{title}</title>
        <meta property='og:image' content={`../../../blogs/heroes/${blogImagePath}.png`}/>
      </Helmet>
      <Link to="/blog" className={PostStyles.backToBlog}>
        <img src="../../back-arrow.svg"></img> Back to the blog
      </Link>
      
      <div className={PostStyles.shareWrapper}>
        <a href={facebookUrl} target="_blank"><img src="../../shareFB.svg"></img></a>
        <a href={linkedinUrl} target="_blank"><img src="../../shareLin.svg"></img></a>
        {/**<img src="../../shareTwitter.svg"></img> */}
        <p className={PostStyles.share}>Share</p>
      </div>
      <div className={PostStyles.postHeader}>
          <div className={PostStyles.title_category}>
            <div className={PostStyles.category}>{category}</div>
            <div> <h1 className={PostStyles.title}>{title}</h1></div>
        <div className={PostStyles.author_date}>
      <div>  <img
          className={PostStyles.authorImage}
          src={`../../blogs/authors/${author}.png`}
          alt="author image"
        ></img></div>
      <div className={PostStyles.authorName_date}> <div><p className={PostStyles.authorName}>{author}</p></div>
        <div className={PostStyles.postTime}>
          <p>
            Date posted: {date}
            {/* <span></span> */}
          <span></span>
          </p></div>  
        </div>
        </div>
        </div>
        <div className={PostStyles.border_bottom}></div>
      </div>
      
      <div className={PostStyles.contentWrapper}>
      <div dangerouslySetInnerHTML={{ __html: html }} />
        <Link to="/blog" className={PostStyles.browseCategories}>
          <img src="../../backArrowRed.svg"></img> Browse all categories
        </Link>
      </div>
    </Layout>
  );
}

export default Template;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(fields: { slug: { eq: $path } }) {
      fields {
        slug
      }
      id
      html
      frontmatter {
        author
        date(formatString: "MMMM DD, YYYY")
        title
        category
        description

      }
    }
  }
`;
